import { useEffect, useRef } from "react";
import image1 from "../../../Assets/HomePageAssets/Group 237668.png";
import image2 from "../../../Assets/HomePageAssets/image 18 (2).png";
import image3 from "../../../Assets/HomePageAssets/image 18.png";
import logo from "../../../Assets/Salesmrkt Logo-05 1.png";

import video from "../../../Assets/videos/Bundle Open 2.5b32ac365a31f4ccfc40.mp4";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { externalLinks } from "../../../constants/external-links";

const CampaignOnDemandSection = () => {
  // const campaignRef = useRef(null);
  // const videoRef = useRef(null);
  // const isOnScreen = useOnScreen(campaignRef);

  // console.log(isOnScreen);

  // useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.autoPlay = !!isOnScreen;
  //   }
  // }, [videoRef]);

  return (
    <div className="w-full bg-[#f8f8f8] py-10 my-10">
      <section className="w-full px-4 max-w-[1560px] mx-auto  lg:grid grid-cols-2 grid-rows-2 gap-4 lg:gap-8 ">
        <div
          className="w-full h-full p-4 flex flex-col md:flex-row lg:flex-col row-span-full group shadow-md bg-white"
          // ref={campaignRef}
        >
          <div className="">
            <h2 className="text-5xl text-black font-bold">
              Campaigns OnDemand
            </h2>
            <h5 className="text-lg text-black font-semibold mt-2">
              All-In-One Solution to Fill Your Pipeline
            </h5>
            <a
              href={externalLinks.calendly.salesbox}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5 className="text-lg text-primary font-semibold">
                Launch Your Campaign {">"}
              </h5>
            </a>
          </div>
          <div className="w-full ">
            <video
              src={video}
              autoPlay={true}
              loop
              // controls
              muted
              width="60%"
              className="mx-auto"
            ></video>
          </div>
        </div>
        <div className="w-full md:h-[260px] p-4 flex flex-col md:flex-row justify-between group shadow-md bg-white">
          <div className="w-full p-4 lg:w-[60%]">
            <h2 className="text-5xl text-black font-bold">
              Sales Team OnDemand
            </h2>
            <h5 className="text-lg text-black font-semibold mt-2">
              Ready to Sell in 5-7 Days
            </h5>
            <a
              href={externalLinks.calendly.salesbox}
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5 className="text-lg text-primary font-semibold">
                Meet Your Enterprise Team {">"}
              </h5>
            </a>
          </div>
          <div className=" w-full lg:w-[40%] h-auto overflow-hidden rounded-r-lg ">
            <img
              src={image2}
              alt=""
              width="100%"
              height="100%"
              className=" group-hover:scale-[1.05] transition-all duration-500 "
            />
          </div>
        </div>
        <div className="w-full md:h-[270px] p-4 flex flex-col md:flex-row justify-between group shadow-md bg-white">
          <div className="w-full p-4 lg:w-[60%]">
            <div>
              <img src={logo} alt="" />
            </div>
            <h5 className="text-lg text-black font-semibold mt-2">
              Where Brands Grow
            </h5>
            <a
              href="https://salesmrkt.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <h5 className="text-lg text-primary font-semibold">
                Step Inside {">"}{" "}
              </h5>
            </a>
          </div>
          <div className="w-full lg:w-[40%] h-auto  rounded-r-lg ">
            <img
              src={image1}
              alt=""
              width="100%"
              height="100%"
              className="group-hover:scale-[1.05]  transition-all duration-500 "
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CampaignOnDemandSection;

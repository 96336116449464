export const externalLinks = {
  calendly: {
    salesbox: "https://calendly.com/salesbox-meet/discovery-call",
  },
  social: {
    linkedin: "https://www.linkedin.com/company/sales-mrkt",
    instagram: "https://www.instagram.com/salesmrkt_/",
    facebook: "https://www.facebook.com/Salesmrkt.B2B.Marketplace",
    youtube: "https://www.youtube.com/@salesmrkt",
  },
};

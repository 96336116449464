import conn from "../../../Assets/HomePageAssets/conn.png";
import connectBg from "../../../Assets/HomePageAssets/connect-bg.png";
import { externalLinks } from "constants/external-links";

const ExpertSection = () => {
  return (
    <section
      className="relative   overflow-hidden bg- bg-cover bg-no-repeat w-full"
      style={{ backgroundImage: `url(${connectBg})` }}
    >
      <div className=" w-full   text-center  pt-8 lg:pt-12">
        <div className="w-full max-w-[1400px] mx-auto px-6 lg:px-0 ">
          <h2 className="lg:text-[3rem] text-[1.2rem]  font-semibold">
            Connect with an Expert
          </h2>
          <p className="lg:mt-4 text-center mx-auto w-[70%]  font-light lg:font-normal lg:w-full text-[.7rem] lg:text-[1.5rem]">
            Ready for a sales revolution? Scale your business with Salesbox’s
            team of specialists by your side.
          </p>
          <div className="flex  justify-center gap-4 mx-auto ">
            <a
              className="mt-6  text-xs lg:text-base px-6 py-2 lg:px-10 lg:py-3  gap-2 flex items-center bg-[#84CC16] text-white font-medium rounded-full"
              href={externalLinks.calendly.salesbox}
              target="_blank"
              rel="noreferrer"
            >
              <div className="bg-white rounded-full w-2 h-2"></div>
              Live Call
            </a>
            <a
              href={externalLinks.calendly.salesbox}
              target="_blank"
              rel="noreferrer"
              className="mt-6 px-3 py-2 lg:px-6 lg:py-3 border text-xs lg:text-base hover:bg-black hover:text-white transition-all  border-black text-black font-semibold rounded-full"
            >
              Schedule a Call
            </a>
          </div>
        </div>
        <div className="w-full ">
          <img src={conn} className="mx-auto" alt="connect" />
        </div>
      </div>
    </section>
  );
};

export default ExpertSection;

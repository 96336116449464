import { useState } from "react";
import video from "../../../Assets/videos/Y2meta.app - Salesbox - Sales Simplified in 2 Minutes.mp4";
import heroBg from "../../../Assets/HomePageAssets/hero-bg.png";
import danny from "../../../Assets/HomePageAssets/danny.png";
import infobg from "../../../Assets/HomePageAssets/hero-infobar.png";

import PlayVideoButton from "Components/Shared/PlayVideo";
import { externalLinks } from "constants/external-links";
import OutlineButton from "Components/Shared/OutlinedButton";

const HeroSectionV2 = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <section
        className={"w-full   z-0 overflow-hidden  bg-cover"}
        style={{ backgroundImage: `url('${heroBg}')` }}
      >
        <div className="text-center mt-8 max-w-[1400px] lg:mt-16 flex flex-col   mx-auto items-center ">
          <p className="font-semibold text-primary leading-[2.2rem]  w-[70%] lg:w-full lg:leading-[5rem] text-[2rem] lg:text-[4rem]">
            Elevate Your B2B Sales
          </p>
          <p className="text-black text-[.8rem] lg:text-[1.5rem]   leading-[2rem] lg:leading-[1rem]">
            Specialized Sales Teams OnDemand{" "}
          </p>
          <div className="flex gap-3 lg:gap-5 mt-1 mb-2 lg:mb-0 lg:mt-10 items-center">
            <PlayVideoButton handleClick={setShowOverlay} />
            <OutlineButton link={externalLinks.calendly.salesbox}>
              Learn more
            </OutlineButton>
          </div>
        </div>

        <div className="w-full">
          <div className="w-full flex  justify-center">
            <img
              src={danny}
              className="object-cover scale-150 lg:scale-100 w-full lg:w-[50%]"
              alt="danny"
            />
          </div>
          <div
            className="absolute z-10 bg-no-repeat -bottom-22 w-full bg-primary-gradient text-white text-center py-2 lg:py-6 bg-cover"
            style={{
              backgroundImage: `url('${infobg}')`,
              backgroundSize: "100% 100%",
            }}
          >
            <p className="text-[.8rem] md:text-[1.2rem] whitespace-nowrap lg:text-[1.4rem]  font-medium tracking-[2px] lg:tracking-[5px]">
              ATTRACT | ENGAGE | CONVERT | SCALE
            </p>
          </div>
        </div>
      </section>

      {/* video modal */}
      <div
        className={`w-screen h-screen   fixed top-0 left-0 bg-black z-[5000]  flex items-center justify-center transition-all ease-in-out duration-300 ${
          showOverlay
            ? "opacity-100 pointer-events-auto "
            : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setShowOverlay(false)}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-full z-[5000] flex items-center justify-center p-4 lg:p-20 mx-auto  relative"
        >
          <button
            className="text-white absolute    top-28 right-16 z-50 fill-white hover:fill-lime-500 transition-all duration-300 ease-in"
            onClick={() => setShowOverlay(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="20px"
              height="20px"
            >
              <path
                d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
                fill=""
              />
            </svg>
          </button>
          {showOverlay && (
            // <iframe
            //   className="w-[calc(100px*3)] h-[calc(100px*2)] md:w-[calc(200px*3)] md:h-[calc(200px*2)] lg:w-[calc(300px*3)] lg:h-[calc(300px*2)] 2xl:w-[calc(300px*3)] 2xl:h-[calc(300px*2)]"
            //   src="https://www.youtube.com/embed/pEkt29HyPCg"
            //   title="Salesbox - Sales Simplified in 2 Minutes"
            //   frameborder="0"
            //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            //   allowfullscreen
            // ></iframe>
            <video
              src={video}
              controls
              autoPlay
              className="aspect-video w-full lg:w-[96%] cursor-pointer z-20"
            ></video>
          )}
        </div>
      </div>
    </>
  );
};

export default HeroSectionV2;

import OutlineButton from "Components/Shared/OutlinedButton";
import campaginBg from "../../../Assets/HomePageAssets/campaigns.png";
import { externalLinks } from "../../../constants/external-links";

const list = [
  {
    title: "5-Day Launch:",
    description: "Zero to launch in just five days.",
  },
  {
    title: "Top Selling Turnkey Solution:",
    description: "The blueprint for your success.",
  },
  {
    title: "Instant Brand & Target Market:",
    description: "Tailored to your brand.",
  },
  {
    title: "Lead Flow & Pipeline Management:",
    description: "Keep your sales engine running smoothly.",
  },
  {
    title: "Expert Sales Team:",
    description: "Our experts, your extended team.",
  },
  {
    title: "Customize For Your:",
    description: "Tailored solutions, because one size does not fit all.",
  },
];

const CampaignsOnDemandV2 = () => {
  return (
    <section className="w-full bg-black mt-20">
      <div className="max-w-[1400px] pt-16 mx-auto lg:grid flex flex-col gap-3 lg:grid-cols-4 ">
        <ul className=" text-white lg:pb-16 lg:grid grid-rows-3 hidden md:pl-5  px-5 md:px-0">
          <div className="row-span-1">
          </div>
          <div className="row-span-2 lg:space-y-16 space-y-4">
            {list.slice(0, 3).map((item) => (
              <li className="flex items-start gap-3">
                <span className="bg-[#84CC16] mt-1 text-white rounded-md min-w-[24px] min-h-[24px] flex items-center justify-center">
                  ✔
                </span>
                <div>
                  <p className="text-lg">{item.title}</p>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </li>
            ))}
          </div>
        </ul>

        <div className="lg:col-span-2 mb-5 lg:mb-0 space-y-3 flex flex-col">
          <div className="text-center text-white mx-auto">
            <p className="font-medium text-[1.5rem] lg:text-[2.5rem]">
              Campaign OnDemand
            </p>
            <p className="text-[.8rem]"> Your Success, Fast-Tracked</p>
          </div>
          <div className="w-fit mx-auto mb-auto">
            <OutlineButton size="md" link={externalLinks.calendly.salesbox}>
              Learn more
            </OutlineButton>
          </div>
          <div className="!mt-auto h-fit w-full">
            <img
              src={campaginBg}
              className="object-contain h-fit"
              alt="campaigns"
            />
          </div>

        </div>
        <ul className=" text-white h-fit lg:hidden  px-5">
          <div className="hidden lg:block lg:row-span-1">
          </div>
          <div className="row-span-2 lg:space-y-16 space-y-4">
            {list.slice(0, 3).map((item) => (
              <li className="flex items-start gap-3">
                <span className="bg-[#84CC16] mt-1 text-white rounded-md min-w-[24px] min-h-[24px] flex items-center justify-center">
                  ✔
                </span>
                <div>
                  <p className="text-lg">{item.title}</p>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </li>
            ))}
          </div>
        </ul>

        <ul className=" text-white grid lg:grid-rows-3 pb-16 lg:-ml-5 -ml-0 px-5 lg:px-0">
          <div className="hidden lg:block lg:row-span-1">

          </div>
          <div className="row-span-2 lg:space-y-16 space-y-4">
            {list.slice(3, 6).map((item) => (
              <li className="flex items-start gap-3">
                <span className="bg-[#84CC16] mt-1 text-white rounded-md min-w-[24px] min-h-[24px] flex items-center justify-center">
                  ✔
                </span>
                <div>
                  <p className="text-lg">{item.title}</p>
                  <p className="text-gray-300">{item.description}</p>
                </div>
              </li>
            ))}
          </div>
        </ul>

      </div>
    </section>
  );
};

export default CampaignsOnDemandV2;

import PlayIcon from "../../Assets/HomePageAssets/play.png";

const PlayVideoButton = ({ handleClick }) => {
  return (
    <button
      className="bg-secondary hover:bg-greenHover text-white md:text-lg  text-xs items-center py-2 px-4 flex gap-2 lg:gap-4 rounded-[90px] lg:py-3 lg:px-6"
      onClick={handleClick}
    >
      <img src={PlayIcon} className="w-3 lg:w-5" alt="play icon" />
      Play Video
    </button>
  );
};

export default PlayVideoButton;

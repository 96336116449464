import meet from "../../Assets/icons/meet.png";
import teams from "../../Assets/icons/teams.png";
import slack from "../../Assets/icons/slack.png";
import zoom from "../../Assets/icons/zoom.png";
import connectUs from "../../Assets/HomePageAssets/connect-us.png";
import { externalLinks } from "../../constants/external-links";

const GrowWithUsSectionV2 = () => {
  return (
    <section className="w-full max-w-[1400px] mx-auto my-24 lg:my-20  lg:py-24 lg:mb-0  relative z-0 px-[5%]">
      <div className="md:ml-[10%] flex justify-center md:justify-between items-center -mb-16">
        <div className="text-center flex flex-col gap-8   items-center px-4 lg:px-0">
          <div className="flex flex-col lg:-mt-12 gap-4">
            <h2 className="lg:text-[3rem] leading-[1.2rem]  lg:leading-[2rem]  text-[1.5rem]  lg:whitespace-nowrap font-medium">
              Let’s Grow & Connect
            </h2>
            <p className="text-[.9rem] lg:mt-5 lg:text-[1.2rem] lg:w-[70%] lg:text-center mx-auto">
              Let Salesbox be the catalyst for your growth. Elevate your sales
              game today.
            </p>
          </div>
          <a
            className="bg-primary-gradient lg:mb-8 text-sm lg:text-base hover:bg-secondary-gradient rounded-[90px] text-white px-16 py-4"
            href={externalLinks.calendly.salesbox}
            target="_blank"
            rel="noreferrer"
          >
            BOOK A MEETING
          </a>
          <div className="flex items-center flex-wrap justify-evenly lg:justify-center w-full lg:gap-6 ">
            <div className="flex gap-4 items-center">
              <img src={meet} alt="" className="lg:w-10 w-6" />
              <img src={teams} alt="" className="lg:w-10 w-6" />
              <img src={slack} alt="" className="lg:w-10 w-6" />
              <img src={zoom} alt="" className="lg:w-16 w-10" />
            </div>
            <div className="flex flex-col    items-center  lg:items-start">
              <p className="font-medium whitespace-nowrap text-xs lg:text-lg">
                {" "}
                Customer Service:
              </p>
              <a
                className="font-medium lg:text-lg text-xs text-[#84CC16] hover:text-black transition-all"
                href="tel:1-888-920-GROW"
                target="_blank"
                rel="noreferrer"
              >
                1-888-920-GROW
              </a>
            </div>
          </div>
        </div>
        <img
          src={connectUs}
          className="hidden md:block md:w-[45%] -mt-32"
          alt="connect"
        />
      </div>
    </section>
  );
};

export default GrowWithUsSectionV2;

import React from "react";

import grow from "../../../Assets/HomePageAssets/grow.png";
import growMobile from "../../../Assets/HomePageAssets/grow-mobile.png";

import growBg from "../../../Assets/HomePageAssets/grow-bg.png";
import { externalLinks } from "../../../constants/external-links";

export const GrowWithUsNew = () => {
  return (
    <section
      className="w-full  grid  pt-6 lg:pt-10 px-[5%] bg-cover"
      style={{ backgroundImage: `url(${growBg})` }}
    >
      <div className="w-full h-full flex flex-col justify-center self-center justify-self-center">
        <h3 className="lg:text-5xl text-[1.5rem] font-medium text-white text-center">
          Grow With Salesbox
        </h3>
        <p className="lg:text-xl text-[.8rem]  px-5  lg:w-full  lg:mt-5 text-white text-center mx-auto">
          Join forces with Salesbox for proven industry solutions.
        </p>
        <div className="flex mx-auto items-center mt-3 lg:mt-7 mb-5">
          <a
            href={externalLinks.calendly.salesbox}
            target="_blank"
            rel="noreferrer"
            className=" bg-white  text-xs lg:text-base rounded-[90px] text-center px-5  py-3 hover:bg-grayHover text-black lg:p-4 lg:px-7"
          >
            LOOK INSIDE SALESBOX
          </a>
        </div>
      </div>
      <div className="justify-center hidden lg:flex">
        <img src={grow} alt="" width="70%" height="70%" />
      </div>
      <div className="justify-center flex lg:hidden">
        <img src={growMobile} alt="" width="100%" height="100%" />
      </div>
    </section>
  );
};

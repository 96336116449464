import ConnectWithExperts from "Components/sections/home/ConnectWithExperts";
import { GrowWithUsNew } from "Components/sections/home/GrowWithUsNew";
import { GrowWithUsSectionV2 } from "../Components/sections";
import {
  CampaignsOnDemandV2,
  HeroSectionV2
} from "../Components/sections/home";

const HomeV2 = () => {
  const list = [
    {
      title: "5-Day Launch:",
      description: "Zero to launch in just five days.",
    },
    {
      title: "Top Selling Turnkey Solution:",
      description: "The blueprint for your success.",
    },
    {
      title: "Instant Brand & Target Market:",
      description: "Tailored to your brand.",
    },
    {
      title: "Lead Flow & Pipeline Management:",
      description: "Keep your sales engine running smoothly.",
    },
    {
      title: "Expert Sales Team:",
      description: "Our experts, your extended team.",
    },
    {
      title: "Customize For Your:",
      description: "Tailored solutions, because one size does not fit all.",
    },
  ];
  return (
    <main className="mt-[5rem] w-screen">
      <HeroSectionV2 />
      <CampaignsOnDemandV2 />
      <div className="bg-white py-1.5"></div>
      <GrowWithUsNew />
      <div className="bg-white py-1.5"></div>
      <ConnectWithExperts />

      <GrowWithUsSectionV2 />
    </main>
  );
};

export default HomeV2;

// background: linear-gradient(270deg, #70C242 0%, #B0D238 100%);
